import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import RedefinirSenhaCliente from './pages/RedefinirSenha/RedefinirSenhaCliente.js';
import RedefinirSenhaColaborador from './pages/RedefinirSenha/RedefinirSenhaColaborador.js';
import VerificarConta from './pages/VerificarConta';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/redefinir-senha/cliente/:token" element={<RedefinirSenhaCliente />} />
        <Route path="/redefinir-senha/colaborador/:token" element={<RedefinirSenhaColaborador />} />
        <Route path="/verificar-conta/:token" element={<VerificarConta />} />
        {/* Outras rotas aqui */}
      </Routes>
    </Router>
  );
}

export default App;
