import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './RedefinirSenha.css';
import CryptoJS from 'crypto-js';
import logo from '../../assets/Logo.png'

const hashPassword = (password) => {
    return CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
};

const RedefinirSenhaColaborador = () => {
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [loading, setLoading] = useState(false);
    const { token } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (senha !== confirmarSenha) {
            setMensagem('As senhas não são iguais.');
            setLoading(false);
            return;
        }

        const hashedPassword = hashPassword(senha); // Calcula o hash da senha

        try {
            const response = await axios.post(`https://api.institutoselmacosso.com.br/auth/esqueci-senha/colaborador/${token}`, { senha: hashedPassword });
            setMensagem(response.data.message);
        } catch (error) {
            setMensagem('Ocorreu um erro ao redefinir a senha. Por favor, tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="redefinir-senha-container">
            <img src={logo} width="70%" alt="Logo" style={{ display: 'block', margin: '0 auto' }} />
            <h1>Redefinir Senha</h1>
            <p>Utilize os campos abaixo para definir a sua nova senha</p>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="senha">Nova senha:</label>
                    <input
                        type="password"
                        id="senha"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmarSenha">Confirmar nova senha:</label>
                    <input
                        type="password"
                        id="confirmarSenha"
                        value={confirmarSenha}
                        onChange={(e) => setConfirmarSenha(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Carregando...' : 'Redefinir Senha'}
                </button>
                {mensagem && <p>{mensagem}</p>}
            </form>
        </div>
    );
};

export default RedefinirSenhaColaborador;
