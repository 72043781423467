import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import logo from '../../assets/Logo.png'


const VerificarConta = () => {
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(null);
    const requestSentRef = useRef(false); // useRef para controlar se a requisição já foi enviada

    useEffect(() => {
        const verificarConta = async () => {
            try {
                const res = await axios.post(`https://api.institutoselmacosso.com.br/auth/verificar-conta/${token}`);
                setResponse(res.data);
                console.log(res)
            } catch (error) {
                setResponse({ error: true, message: error.message });
            } finally {
                setLoading(false);
            }
        };

        // Verificar se a requisição já foi enviada
        if (!requestSentRef.current) {
            verificarConta();
            requestSentRef.current = true; // Marcar que a requisição foi enviada
        }
    }, [token]); // Removido o requestSent dos argumentos do useEffect, pois useRef não causa renderização

    return (
        <div style={styles.container}>
            <img
  src={logo}
  alt="Logo"
  style={{
    display: 'block',
    margin: '0 auto',
    height: 'auto',
    width: '60%', // Estilo padrão para telas maiores
  }}
/>

            {loading ? (
                <p style={styles.loadingMessage}>Verificando a sua conta...</p>
            ) : (
                <p style={styles.responseMessage(response.error)}>
                    {response.error ? `Erro: ${response.message}` : response.message}
                </p>
            )}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
    },
    loadingMessage: {
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    responseMessage: (isError) => ({
        fontSize: '1rem',
        fontWeight: 'bold',
        color: isError ? 'red' : 'green',
        textAlign: 'center'
    }),
};

export default VerificarConta;
