import React from 'react';

const Home = () => {
    return (
        <div>
            <h1>App Instituto Selma Cosso</h1>
        </div>
    )
}

export default Home;
